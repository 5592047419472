//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import EStoreProductList from '@/components/EStoreProductList.vue';
import EStoreOrdersHistory from '@/views/EStoreOrdersHistory.vue';
import EStoreCart from '@/components/EStoreCart.vue';
import store from '../store/index';

export default {
  name: 'EStore',
  components: {
    EStoreProductList,
    EStoreOrdersHistory,
    EStoreCart
  },
  data () {
    return {
      tab_selected: "cart",
      cart_info : {
                    cart_no: "",
                    type: "1",
                    for_mcode: "",
                    for_mname: "",
                    //delivery_by: "2",
                    delivery_by: "1",
                    branch_selected: "", 
                    receiver_name: "",
                    address1: "",
                    address2: "",
                    province_selected: "", 
                    zipcode: "",
                    phone_no: "",
                    mobile_no: "" 
                  }
    }
  },
  methods: {
    setCartInfo: function(cart_info) {
      this.cart_info = cart_info;
      //alert("change cart_info");
    },
    changeTab: function (tabName) { 
      this.tab_selected = tabName;
    },
    alertTabName: function () {
      //alert("alertTabName : " +this.tab_selected);
    }
  },
  mount() {
    //console.log("mount current tab : " + this.tab_selected);
  },
  created () {
    store.set("APP_TOOLBAR_TITLE","สั่งซื้อสินค้า");
  }
}

